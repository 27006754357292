import request from '@/utils/request'

// 新增会话
export function addConversation() {
  return request.post('/admin/api/v1/ai/addAiSession')
}

// 删除会话
export function deleteConversation(id) {
  return request({
    url: '/admin/api/v1/ai/deleteAiSession/' + id,
    method: 'delete',
  })
}
// 查询会话详情
export function getConversationDetail(id) {
  return request({
    url: '/admin/api/v1/ai/' + id,
    method: 'get',
  })
}
// 查询左侧会话列表
export function getConversation() {
  return request.get('/admin/api/v1/ai/querySionByUserId')
}
