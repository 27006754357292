import request from '@/utils/request'

/**
 * 提交问题
 *
 * @param queryParams
 */
export function postProblem(data) {
  return request.post('/website/api/v1/websiteProblem', data)
}

/**
 * 修改问题
 *
 * @param queryParams
 */
export function putProblem(data) {
  return request.put('/website/api/v1/websiteProblem', data)
}
/**
 * 删除问题
 *
 * @param queryParams
 */
export function deleteProblem(id) {
  return request.delete(`/website/api/v1/websiteProblem/${id}`)
}

/**
 * 问题列表
 *
 * @param queryParams
 */
export function getProblem(data) {
  return request.post('/website/api/v1/websiteProblem/page', data)
}

/**
 * 新增产品功能
 *
 * @param queryParams
 */
export function postWebsiteProFunction(data) {
  return request.post('/website/api/v1/websiteProFunction', data)
}

/**
 * 产品功能分页列表
 *
 * @param queryParams
 */
export function websiteProFunctionList(data) {
  return request.post('/website/api/v1/websiteProFunction/page', data)
}

//   修改
//    刪除
/**
 * 修改产品功能
 *
 * @param queryParams
 */
export function putWebsiteProFunction(data) {
  return request.put('/website/api/v1/websiteProFunction', data)
}

/**
 * 删除产品功能
 *
 * @param queryParams
 */
export function delWebsiteProFunction(id) {
  return request.delete(`/website/api/v1/websiteProFunction/${id}`)
}

/**
 * 上传图片
 *
 * @param queryParams
 */
export function uploadIMG(data) {
  return request.post(`/website/api/v1/file`, data)
}

/**
 * 上传文件
 *
 * @param file
 */
export function uploadFile(file) {
  const formData = new FormData()
  formData.append('file', file)
  return request({
    url: '/website/api/v1/file',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
// 新增轮播
export function postBanner(data) {
  return request.post('/website/api/v1/websiteBanner', data)
}
// 修改轮播
export function putBanner(data) {
  return request.put('/website/api/v1/websiteBanner', data)
}
// 删除轮播
export function delWebsiteBanner(id) {
  return request.delete(`/website/api/v1/websiteBanner/${id}`)
}
// 轮播列表
export function websiteBannerList(data) {
  return request.post('/website/api/v1/websiteBanner/page', data)
}
