<template>
  <div class="container">
    <!-- 左侧 -->
    <div class="left-box" :class="{ closed: isSidebarClosed }">
      <div class="left-container">
        <el-button type="primary" size="large" @click="createNewChat"
          >新建对话</el-button
        >
        <div class="list-box" style="">
          <div
            class="chat-item"
            v-for="(chat, index) in chatList"
            :key="index"
            @click="selectChat(chat, index)"
            @mouseover="overChat(chat, index)"
            :class="{ active: selectIndex === index }"
          >
            <span>{{ chat.name ? chat.name : "New Chat" }}</span>
            <span v-if="state.overIndex == index">
              <Delete
                @click="deleteChat(chat)"
                style="width: 1em; height: 1em"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="toggle-icon" @click="toggleSidebar">
        <img
          v-if="isSidebarClosed"
          style="width: 24px"
          src="@/assets/left-icon.png"
          alt=""
        />
        <img v-else style="width: 32px" src="@/assets/right-icon.png" alt="" />
      </div>
    </div>
    <!-- 右侧 -->
    <div class="right-box">
      <div style="height: 100%" class="selected-chat">
        <!-- 聊天大区域 -->
        <div class="chatbox" id="chat-box">
          <div
            v-for="(item, index) in messageList"
            :key="index"
            class="message-container"
          >
            <div class="user-message" v-if="item.fromMsg">
              <div>
                <img
                  src="https://pic3.zhimg.com/v2-6a49fdf146de95629b68aeda96c0125e_r.jpg"
                  alt="GPT Avatar"
                  class="avatar"
                />
              </div>
              <div class="message-time" v-if="item.createTime">
                Sent at: {{ item.createTime }}
              </div>
              <div class="user-content">{{ item.fromMsg }}</div>
            </div>
            <div class="gpt-message">
              <img src="@/assets/erjinzhi.jpg" class="avatar" />
              <div class="message-time" v-if="item.createTime">
                Sent at: {{ item.createTime }}
              </div>
              <div>
                <div v-show="item.flag && !item.toMsg">
                  <!-- 加载动画 -->
                  <ul class="flex-loading">
                    <li style="--time: 1"></li>
                    <li style="--time: 2"></li>
                    <li style="--time: 3"></li>
                    <li style="--time: 4"></li>
                    <li style="--time: 5"></li>
                    <li style="--time: 6"></li>
                    <li style="--time: 7"></li>
                    <li style="--time: 8"></li>
                  </ul>
                </div>
                <!-- 打字机输入 -->
                <div
                  v-show="item.flag && item.toMsg"
                  ref="gptRef"
                  :id="`multipleStrings${index}`"
                  class="gpt-content"
                ></div>
                <!-- 历史记录 -->
                <div
                  v-html="item.toMsg"
                  v-if="!item.flag"
                  class="gpt-content"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="stopStatus" class="stop" @click="stop">停止回答</div>
        <!-- 输入区域 -->
        <div class="input-box">
          <textarea
            class="message-input"
            placeholder="输入你想问的问题"
            v-model="question"
            @keydown.enter="sendOnEnter"
          ></textarea>
          <el-button
            type="primary"
            :disabled="sendDisabled"
            class="send-button"
            id="sendButton"
            @click="sendFn()"
          >
            发送
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import TypeIt from "typeit"; //打字机
import { ElMessage, ElMessageBox, emitChangeFn } from "element-plus";
import { onMounted, reactive, ref, toRefs, watch } from "vue";
import {
  addConversation,
  deleteConversation,
  getConversationDetail,
  getConversation,
} from "@/api/sys/tool/gpt.js";
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
} from "@element-plus/icons-vue";
import consumablesApi from "@/api/erp/material/consumablesApi";
let textArr = [];
const state = reactive({
  question: "",
  chatId: 0,
  chatList: [],
  messageList: [],
  activeChat: null,
  selectIndex: 0,
  isSidebarClosed: false,
  eventSource: null,
  messages: [],
  strings: "",
  strArr: [],
});
const {
  question,
  chatList,
  messageList,
  activeChat,
  selectIndex,
  isSidebarClosed,
} = toRefs(state);

let eventSource = "";
let Ainstance;
const showFullText = ref(true);
const sendDisabled = ref(false);
const stopStatus = ref(false);

function selectChat (chat, index) {
  const id = chat.id;
  state.activeChat = chat;
  state.selectIndex = index;
  getConversationDetailFn(id);
}
function overChat (chat, index) {
  state.overIndex = index;
}
// 查询会话详情
function getConversationDetailFn(id) {
  getConversationDetail(id).then((res) => {
    // console.log("res.data", res.data);
    res.data.forEach((e) => {
      if (!e.toMsg) return;
      e.toMsg = e.toMsg.replace(/\n/g, "<br>");
    });
    // console.log("newArr", res.data);
    state.messageList = res.data;
    state.messageList.unshift({
      toMsg:
        "你好,我是二进制智能AI,我既能写文案、想点子,又能陪你聊天,输入你想问的问题试试吧！",
    });
    state.activeChat.messageList = res.data;
    //查询详情后自动滚动到最新
    scrollToBottom();
  });
}
function toggleSidebar () {
  state.isSidebarClosed = !state.isSidebarClosed;
}
function createNewChat () {
  if (state.chatList.length < 10) {
    // 新增会话
    addConversation().then((res) => {
      getConversationFn("1");
      getConversationDetailFn(res.data); //新增完之后立刻查询聊天记录
    });
  } else {
    ElMessage({
      type: "warning",
      message: "每人最多只能创建十个会话",
    });
  }
}
// 查询左侧会话列表
function getConversationFn (ease) {
  getConversation().then((res) => {
    console.log("查询左侧会话列表", res);
    //如果左侧聊天列表无会话则自动新建并选择查看会话详情
    if (res.data && res.data.length == 0) {
      createNewChat();
    } else if (ease == "2") {
      //初次加载默认选中最后一条并查看会话详情
      state.selectIndex = res.data.length - 1;
      state.activeChat = res.data[res.data.length - 1];
      getConversationDetailFn(res.data[res.data.length - 1].id);
    }
    state.chatList = res.data;
    if (ease == "1") {
      state.activeChat = state.chatList[state.chatList.length - 1];
      state.selectIndex = state.chatList.length - 1;
    }
  });
}
function deleteChat(chat) {
  deleteConversation(chat.id).then((res) => {
    getConversationFn("2");
  });
}

function handleStreamData (msg) {
  let str = msg.replace(/\"/g, "");
  str = str.replace(/\\n/g, "<br>");
  if (!str) return;
  state.strArr.push(str);
  stopStatus.value = true;
}
function sendOnEnter(event) {
  event.preventDefault();
  if (event.key === "Enter" && !sendDisabled.value) {
    sendFn();
  } else {
    event.preventDefault();
  }
}

function stop () {
  // state.messageList[state.messageList.length - 1].toMsg =  "停止生成";
  // state.messageList[state.messageList.length - 1].flag = false;
  if (Ainstance) {
    Ainstance.freeze();
  }
  stopStatus.value = false;
  sendDisabled.value = false;
}
function sendFn () {
  if (state.question === "") {
    ElMessage({
      type: "error",
      message: "你没有输入内容哦",
    });
    return;
  }
  sendDisabled.value = true;
  // 1
  state.messageList.push({
    fromMsg: state.question,
    toMsg: "",
    flag: true,
  });

  state.activeChat.messageList = state.messageList;
  scrollToBottom();
  const key = state.activeChat.id;
  //点击发送后 stop出现
  stopStatus.value = true;
  // 建立链接 流方法获取数据
  eventSource = new EventSource(
    `/admin/api/v1/ai/send?question=${encodeURIComponent(
      state.question
    )}&key=${encodeURIComponent(key)}`
  );
  eventSource.onmessage = function (event) {
    // consolestringsnewArr .log("event.data", event.data);
    handleStreamData(event.data);
  };

  state.question = "";
  //发送完更新左侧对应的最新会话记录（2不走查看详情）
  getConversationFn();
  eventSource.onerror = function () {
    setTimeout(() => {
      eventSource.close();
      state.strings = state.strArr.join("");
      console.log("strings", state.strings);
      // console.log("strArr", state.strArr);
      let idx = state.messageList.length - 1;
      Ainstance = new TypeIt(`#multipleStrings${idx}`, {
        strings: state.strings,
        speed: 20,
        waitUntilVisible: true,
        cursor: false,
        breakLines: true,
        afterStep: function (step, instance) {
          scrollToBottom();
        },
        afterComplete: () => {
          sendDisabled.value = false; // 在动画完成后执行的操作
        },
      }).go();
      state.activeChat.messageList[idx].toMsg = state.strings;
      state.strings = "";
      state.strArr = [];
    }, 1000);
  };
}

function scrollToBottom () {
  const chatBox = document.getElementById("chat-box");
  // 使用 requestAnimationFrame 来确保在下一次重绘之前获取正确的 scrollHeight
  requestAnimationFrame(() => {
    chatBox.scrollTop = chatBox.scrollHeight;
  });
}

onMounted(() => {
  getConversationFn("2");
});
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-shadow: 0 10px 30px rgba(97, 132, 255, 0.07),
    0 4px 8px rgba(97, 132, 255, 0.0417), 0 2px 4px rgba(97, 132, 255, 0.0283);
}

.left-box {
  position: relative;
  width: 30%;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease, padding 0.3s ease;
  padding: 10px;
}
.toggle-icon {
  position: absolute;
  top: 50%;
  right: -29px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .list-box {
    margin-top: 20px;
  }
}

.chat-item {
  height: 60px;
  line-height: 60px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 5px;
  box-shadow: 0 10px 30px rgba(97, 132, 255, 0.07),
    0 4px 8px rgba(97, 132, 255, 0.0417), 0 2px 4px rgba(97, 132, 255, 0.0283);
}
.chat-item span:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;
}

.chat-item.active {
  background-color: lightblue !important;
}
.chat-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.closed {
  width: 0;
  padding: 0;
}

.right-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 65%;
  background-color: rgb(244, 243, 246);
}
.chatbox {
  height: 80%;
  padding: 10px 0;
  overflow-y: scroll;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0 16px 20px 0 rgba(174, 167, 223, 0.06);
}
.stop {
  padding: 0 7px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: rgb(242, 201, 125);
  border-radius: 4px;
  margin: auto;
  cursor: pointer;
  color: #fff;
}
.chatbox::-webkit-scrollbar {
  width: 10px;
}

.input-box {
  width: 100%;
  height: 100px;
  bottom: 0;
  background-color: #f9f9f9;
  padding: 10px;
  display: flex;
}

.message-input {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.send-button {
  height: 100%;
  padding: 10px 20px;
  margin-left: 10px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.selected-chat {
  width: 100%;
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.message-container {
  display: flex;
  margin: 10px;
  flex-direction: column;
}
.one {
  display: flex;
  justify-content: flex-start;
}
.two {
  display: flex;
  justify-content: flex-end;
}

.gpt-message {
  align-self: flex-start;
  border-radius: 10px;
}
.gpt-content {
  background-color: rgb(136, 136, 136);
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  max-width: 500px;
  word-wrap: break-word;
  margin-top: 5px;
}
.user-content {
  padding: 10px;
  border-radius: 10px;
  background-color: #007bff;
  max-width: 500px;
  word-wrap: break-word;
  margin-top: 5px;
}
.user-message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  color: white;
  padding: 10px;
  border-radius: 10px;
  align-self: flex-end;
}

.message-time {
  font-size: 12px;
  color: #777;
}

.flex-loading {
  width: 80px;
  height: 40px;
  background-color: #f7cc8b;
  padding-top: 10px;
  padding-left: 6px;
  color: #ffffff;
  border-radius: 10px;
}

.flex-loading li {
  width: 4px;
  height: 6px;
  display: inline-block;
  background: #ffffff;
  margin: 0 2px;
  border-radius: 1px;
  /* 动画名：flex-loading，
             * 执行时长：0.8s，
             * 动画执行速度：ease-in-out(指定开始和结束较慢的动画)，
             * 动画执行次数：infinite(无限次) */
  animation: flex-loading 0.8s ease-in-out infinite;
  /* 每个小格子的伸缩动画分别延迟0.1s，0.2s，0.3s...执行，
             * var(--time)使用CSS3变量 */
  animation-delay: calc(0.1s * var(--time));
}

@keyframes flex-loading {
  0%,
  25%,
  50% {
    transform: scaleY(1);
  }
  /* 小格子纵向伸展到原来的4倍 */
  75% {
    transform: scaleY(4);
  }
}
</style>
