import { createApp } from "vue";
import "element-plus/dist/index.css";
import App from "./App.vue";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "@/assets/css/global.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import * as ElementPlusIconsVue from
  '@element-plus/icons-vue'
import "@/assets/css/css-vars.css";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import { hasPerm, dataType } from "@/utils/permission";
import print from "vue3-print-nb";
import * as filters from "./filters"; // global filters
import { listDictsByCode } from "@/api/sys/admin/dict";
import Pagination from "@/components/Pagination/index.vue";
import GlobalTable from "@/components/Table/GlobalTable/index";
import mitt from "mitt";


// 去除生产环境的console
if (['production', 'staging'].includes(process.env.NODE_ENV)) {
  window.console.log = () => { }
}
const emitter = mitt();
const app = createApp(App);
// 去除警告信息
app.config.warnHandler = () => null;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store);
app.use(ElementPlus, {
  locale: zhCn,
  size: "default",
});
app.config.globalProperties.listDictsByCode = listDictsByCode;
app.use(hasPerm);
app.use(dataType);
app.use(print);
app.component("Pagination", Pagination);
app.component("GlobalTable", GlobalTable);
app.provide("emitter", emitter); // 注入provider
app.use(router).mount("#app");
