import request from "@/utils/request";

/**
 * 获取系统下的套餐列表
 *
 * @param queryParams
 */
export function getPackageListApi(queryParams) {
  return request({
    url: "/admin/api/v1/sysTariffPackages/list",
    method: "get",
    params: queryParams,
  });
}
/**
 * 获取剩余套餐类型
 *
 */
export function getRemainPackageTypeApi(dictCode) {
  return request({
    url: `/admin/api/v1/dict-items/selectRemainTariffList/${dictCode}`,
    method: "get",
  });
}

/**
 * 新增套餐类型
 *
 * @param data
 */
export function addPackageTypeApi(data) {
  return request({
    url: "/admin/api/v1/sysTariffPackages/save",
    method: "post",
    data: data,
  });
}
/**
 * 查询套餐详情
 *
 * @param data
 */
export function getPackageTypeDetailApi(id) {
  return request({
    url: `/admin/api/v1/sysTariffPackages/detail/${id}`,
    method: "get",
  });
}

/**
 * 修改套餐类型
 *
 * @param data
 */
export function updatePackageTypeApi(data) {
  return request({
    url: "/admin/api/v1/sysTariffPackages/update",
    method: "put",
    data: data,
  });
}
/**
 * 删除套餐类型
 *
 */
export function delPackageTypeApi(id) {
  return request({
    url: `/admin/api/v1/sysTariffPackages/delete/${id}`,
    method: "delete",
  });
}
