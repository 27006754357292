<template>
  <el-container>
    <el-aside>
      <AsideMenu ref="leftMenu" />
    </el-aside>
    <el-container>
      <el-header>
        <div class="top-nav">
          <!-- <HeaderNavbtn/> -->
          <HeaderTab ref="headerTab" @clear="clearCache" />
          <HeaderAvatar @changeSKin="changeSKin" />
        </div>
      </el-header>
      <el-main style="flex: 1; height: 100%">
        <router-view v-slot="{ Component }">
          <keep-alive ref="keepAlive">
            <component
              :is="Component"
              v-if="$route.meta.keepAlive"
              :key="$route.query.title"
            />
          </keep-alive>
          <component
            :is="Component"
            v-if="!$route.meta.keepAlive"
            :key="$route.query.title"
          />
        </router-view>
        <el-backtop :right="50" :bottom="50" />
        <!-- 悬浮 -->
        <div class="gpt-float" ref="gptFloat" @mousedown="handleMouseDown">
          <img class="gpt-icon" src="@/assets/gpt.png" alt="" />
        </div>
      </el-main>
    </el-container>
    <!-- gpt -->
    <el-drawer v-model="zixunShow" :with-header="false" size="45%">
      <zixund></zixund>
    </el-drawer>
  </el-container>
</template>
<script setup>
import zixund from "./components/zixun";
/* import HeaderNavbtn from "./components/Header/HeaderNavbtn"; */
import { ref, reactive, onMounted, inject, getCurrentInstance } from "vue";
import HeaderTab from "./components/Header/HeaderTab";
import HeaderAvatar from "./components/Header/HeaderAvatar";
import AsideMenu from "./components/Aside/AsideMenu";
import { useRoute, useRouter } from "vue-router";
const gptFloat = ref(null); // 获取 gpt-float 元素的引用

let isDragging = false;
let offsetX = 0;
let offsetY = 0;
let hasDragged = false;


function handleMouseDown (event) {
  event.preventDefault();
  isDragging = true;
  offsetX = event.clientX - gptFloat.value.getBoundingClientRect().left;
  offsetY = event.clientY - gptFloat.value.getBoundingClientRect().top;
}

function handleMouseMove (event) {
  if (!isDragging) return;
  hasDragged = true; // 拖拽过程中设置标志位为true
  const x = event.clientX - offsetX;
  const y = event.clientY - offsetY;
  gptFloat.value.style.left = `${x}px`;
  gptFloat.value.style.top = `${y}px`;
}


function handleMouseUp (event) {
  // console.log(isDragging)
  if (isDragging) {
    event.preventDefault();
    event.stopPropagation();
  }
  isDragging = false;
  if (!hasDragged) {
    // console.log(event)
    if (event.target.classList.contains('gpt-icon') || event.target.className == "gpt-float") {
      zixun();
    }
  }
  // 重置拖拽标志位
  hasDragged = false;
}

// 添加事件监听器
document.addEventListener("mousemove", handleMouseMove);
document.addEventListener("mouseup", handleMouseUp);

const zixunShow = ref(false)
function zixun () {
  zixunShow.value = !zixunShow.value
}
const emitter = inject("emitter"); // Inject `emitter`
const headerTab = ref(HeaderTab);
const keepAlive = ref();
const leftMenu = ref();
let router = useRouter();

function clearCache (activeName) {
  removeCache(activeName);
  router.replace({
    path: "/blank",
    query: { refresh: new Date(), title: "blank" },
  });
  setTimeout(function () {
    headerTab.value.showTab(activeName);
  }, 500);
}

function removeCache (activeName) {
  if (keepAlive.value) {
    keepAlive.value.$pruneCacheEntry(activeName);
  }
}

function changeSKin () {
  leftMenu.value.goHome();
}

emitter.on("removeCache", (value) => {
  // 监听事件
  removeCache(value);
});
</script>
<style>
.el-header {
  height: 34px !important;
}
.el-aside {
  width: 64px !important;
}
.el-main {
  padding: 0px !important;
  min-width: 1280px;
}
#menubar {
  position: fixed;
  left: 0;
  z-index: 999;
}
#menubar .el-overlay {
  left: 64px !important;
}
#menubar .el-drawer {
  min-width: 176px;
}
.top-nav {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 64px;
  z-index: 998;
  padding: 0px 100px 0 0;
}
.top-nav {
  display: flex;
  background-color: #fff;
  align-items: center;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}
.dark .top-nav {
  display: flex;
  background-color: #313131;
  align-items: center;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}
.el-backtop {
  color: #fff;
  background-color: rgb(0 0 0 / 62%);
}
.el-backtop:hover {
  background-color: rgb(0 0 0 / 80%);
}
.gpt-float {
  position: fixed;
  bottom: 100px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 62%);
  z-index: 99;
  width: 100px;
  height: 100px;
  border-radius: 100%;
}
.gpt-float {
  /* 其他样式 */
  /* position: fixed; */
  /* 允许鼠标拖动 */
  cursor: grab;
}
</style>